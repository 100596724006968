<template>
  <div class="content-wrapper">
    <PageHeader screenName="Cadastrar nova" :linkItems="linkItems" />

    <ConfirmModal
      itemIdentifier="usuário"
      :modalShow="modal.showModal"
      :typeModal="modal.typeModal"
      @confirmed="redirecionarLista"
      @canceled="closeModalConfirm"
    />

    <SucessoModal
      :modalShow="modalSucesso.showModal"
      :typeModal="modalSucesso.typeModal"
      :caseModal="modalSucesso.caseModal"
      @confirmed="redirecionarLista"
    />

    <TimedAlert
      variant="success"
      message="Vacina salva com sucesso!"
      :show="savedAlert"
    />

    <main class="card p-2">
      <section class="form">
        <validation-observer ref="vacinaRules">
          <b-form>
            <b-row>
              <b-col lg="6" md="6" sm="12">
                <b-form-group
                  label="Nome técnico da vacina*"
                  label-for="nome-tecnico-vacina-input"
                  label-class="label_size_vacina"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NomeTecnicoVacina"
                    rules="required"
                  >
                    <b-form-input
                      :state="
                        errors.length > 0 ||
                        form.errorApi.nomeTecnico ||
                        form.errorApi.error
                          ? false
                          : null
                      "
                      id="nome-tecnico-vacina-input"
                      v-model="form.nomeTecnico"
                      placeholder="Ex: Varicela"
                      v-mask="'X'.repeat(250)"
                    />
                    <small class="text-muted">
                      Nome conhecido no meio científico.
                    </small>
                    <br />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="form.errorApi.nomeTecnico" class="text-danger">
                      {{ form.errorApi.nomeTecnico }}
                    </small>
                    <small v-if="form.errorApi.error" class="text-danger">
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <SelectDoencas
                  @GetDoenca="setDoenca"
                  :values="doencasSelecionadas"
                  :errorApi="form.errorApi.doencasSelecionadas"
                />
              </b-col>
              <b-col lg="3" md="6" sm="12">
                <b-form-group
                  label="Nome popular da vacina"
                  label-for="nome-popular-vacina-input"
                  label-class="label_size_vacina"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NomePopularVacina"
                  >
                    <b-form-input
                      :state="
                        errors.length > 0 || form.errorApi.nomePopular
                          ? false
                          : null
                      "
                      id="nome-popular-vacina-input"
                      v-model="form.nomePopular"
                      placeholder="Ex: Catapora"
                      v-mask="'X'.repeat(250)"
                    />
                    <small class="text-muted">
                      Nome conhecido popularmente.
                    </small>
                    <br />
                    <small v-if="form.errorApi.nomePopular" class="text-danger">
                      {{ form.errorApi.nomePopular }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <validation-provider
                  #default="{ errors }"
                  name="TipoAplicaçãoVacina"
                  rules="required"
                >
                  <b-form-group
                    label="Tipo de aplicação*"
                    label-for="id-tipo-aplicacao-selecionado-input"
                    label-class="label_size_vacina"
                    :state="
                      errors.length > 0 ||
                      form.errorApi.idTipoAplicacaoSelecionado
                        ? false
                        : null
                    "
                  >
                    <v-select
                      id="id-tipo-aplicacao-selecionado-input"
                      v-model="form.idTipoAplicacaoSelecionado"
                      :options="tiposAplicacaoSelect"
                      label="descricao"
                      placeholder="Selecione a quantidade"
                      :clearable="false"
                    >
                      <span slot="no-options">Nenhuma opção selecionável.</span>
                    </v-select>
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.idTipoAplicacaoSelecionado"
                      class="text-danger"
                    >
                      {{ form.errorApi.idTipoAplicacaoSelecionado }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <validation-provider #default="{ errors }" name="Código SMD da vacina" rules="max:7">
                  <b-form-group
                    label="Código SMD da Vacina"
                    label-for="codigo-smd-input"
                    label-class="label_size_vacina"
                    :state="
                      errors.length > 0 ||
                      form.errorApi.idTipoAplicacaoSelecionado
                        ? false
                        : null
                    "
                  >
                    <b-form-input
                      :state="
                        errors.length > 0 || form.errorApi.codeSmdVaccine
                          ? false
                          : null
                      "
                      id="codigo-smd-input"
                      v-model="form.codeSmdVaccine"
                    />
                    <br />
                    <small v-if="form.errorApi.codeSmdVaccine" class="text-danger">
                      {{ form.errorApi.codeSmdVaccine }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <validation-provider #default="{ errors }" name="código para integração externa" rules="max:7">
                  <b-form-group
                    label="Código para integração externa"
                    label-for="code-external-input"
                    label-class="label_size_vacina"
                    :state="
                      errors.length > 0 ||
                      form.errorApi.idTipoAplicacaoSelecionado
                        ? false
                        : null
                    "
                  >
                    <b-form-input
                      :state="
                        errors.length > 0 || form.errorApi.codeExternalIntegration
                          ? false
                          : null
                      "
                      id="code-external-input"
                      v-model="form.codeExternalIntegration"
                    />
                    <br />
                    <small v-if="form.errorApi.codeExternalIntegration" class="text-danger">
                      {{ form.errorApi.codeExternalIntegration }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="12" md="12" sm="12">
                <b-form-group
                  label="Descrição*"
                  label-for="descricao-vacina-input"
                  label-class="label_size_vacina"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="DescricaoVacina"
                    rules="required"
                  >
                    <b-form-textarea
                      :state="
                        errors.length > 0 || form.errorApi.descricao
                          ? false
                          : null
                      "
                      id="descricao-vacina-input"
                      rows="5"
                      placeholder="Adicione uma descrição para esta vacina"
                      v-model="form.descricao"
                      no-resize
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="form.errorApi.descricao" class="text-danger">
                      {{ form.errorApi.descricao }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="12" md="12" sm="12">
                <b-form-group
                  label="Contraindicações*"
                  label-for="contraindicacao-vacina-input"
                  label-class="label_size_vacina"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="ContraindicacaoVacina"
                    rules="required"
                  >
                    <b-form-textarea
                      :state="
                        errors.length > 0 || form.errorApi.contraindicacao
                          ? false
                          : null
                      "
                      id="contraindicacao-vacina-input"
                      rows="5"
                      placeholder="Adicione contraindicações para esta vacina"
                      v-model="form.contraindicacao"
                      no-resize
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.contraindicacao"
                      class="text-danger"
                    >
                      {{ form.errorApi.contraindicacao }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="12" md="12" sm="12">
                <b-form-group
                  label="Possíveis eventos adversos*"
                  label-for="efeito-colateral-vacina-input"
                  label-class="label_size_vacina"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="EfeitoColateralVacina"
                    rules="required"
                  >
                    <b-form-textarea
                      :state="
                        errors.length > 0 || form.errorApi.efeitoColateral
                          ? false
                          : null
                      "
                      id="efeito-colateral-vacina-input"
                      rows="5"
                      placeholder="Adicione possíveis eventos adversos para esta vacina"
                      v-model="form.efeitoColateral"
                      no-resize
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.efeitoColateral"
                      class="text-danger"
                    >
                      {{ form.errorApi.efeitoColateral }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="12" md="12" sm="12">
                <b-form-group
                  label="Cuidados antes, durante e após a vacinação*"
                  label-for="cuidados-vacina-input"
                  label-class="label_size_vacina"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CuidadosVacina"
                    rules="required"
                  >
                    <b-form-textarea
                      :state="
                        errors.length > 0 || form.errorApi.cuidados
                          ? false
                          : null
                      "
                      id="cuidados-vacina-input"
                      rows="5"
                      placeholder="Adicione cuidados para esta vacina"
                      v-model="form.cuidados"
                      no-resize
                    />
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small v-if="form.errorApi.cuidados" class="text-danger">
                      {{ form.errorApi.cuidados }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </section>
      <section class="buttons mt-3">
        <b-button
          id="save-vacina"
          class="mr-2"
          variant="custom-blue"
          @click.prevent="validationForm(true)"
          :disabled="saving || savingAndNew"
        >
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Salvar vacina</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          ></span>
        </b-button>
        <b-button
          id="save-vacina"
          class="mr-2"
          variant="custom-blue"
          @click.prevent="validationForm(false)"
          :disabled="saving || savingAndNew"
        >
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Salvar e cadastrar uma nova</span>
          <span
            v-if="savingAndNew"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          ></span>
        </b-button>
        <b-button
          id="cancel-register-vacina"
          :disabled="saving || savingAndNew"
          variant="outline-danger"
          @click.prevent="openModalConfirm"
        >
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </section>
    </main>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import ConfirmModal from "@/views/components/custom/modals/ConfirmModal.vue";
import SelectDoencas from "@/views/custom-pages/vacina/Select-doencas.vue";
import SucessoModal from "@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue";
import handleErrorsService from "./Services/HandleErrors";

export default {
  title: "Cadastrar vacina",

  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    TimedAlert,
    PageHeader,
    ConfirmModal,
    SelectDoencas,
    SucessoModal,
  },

  data() {
    return {
      linkItems: [
        {
          name: "Vacinas",
          routeName: "vacina-list",
        },
        {
          name: "Cadastrar nova",
          active: true,
        },
      ],
      modal: {
        showModal: false,
        typeModal: "danger",
      },
      modalSucesso: {
        showModal: false,
        typeModal: "success",
        caseModal: "criacao",
      },
      required,
      savedAlert: false,
      saving: false,
      savingAndNew: false,
      form: {
        nomeTecnico: "",
        nomePopular: "",
        descricao: "",
        contraindicacao: "",
        efeitoColateral: "",
        cuidados: "",
        idTipoAplicacaoSelecionado: null,
        codeSmdVaccine:null,
        codeExternalIntegration: null,
        errorApi: {
          nomeTecnico: "",
          nomePopular: "",
          descricao: "",
          contraindicacao: "",
          efeitoColateral: "",
          cuidados: "",
          idTipoAplicacaoSelecionado: "",
          doencasSelecionadas: "",
          error: "",
          codeSmdVaccine:"",
          codeExternalIntegration:""
        },
      },
      doencasSelecionadas: [],
      tiposAplicacaoSelect: [],
    };
  },

  mounted() {
    this.$http
      .get(this.$api.getTiposAplicacao(), {
        params: {
          ativo: true,
        },
      })
      .then(({ data }) => {
        this.tiposAplicacaoSelect = data;
      });
  },

  methods: {
    validationForm(redirectToList) {
      this.$refs.vacinaRules.validate().then((success) => {
        if (success) {
          if (redirectToList) {
            this.saving = true;
          } else {
            this.savingAndNew = true;
          }
          this.saveVacina(redirectToList);
        }
      });
    },

    saveVacina(redirectToList) {
      const parameters = {
        nome_tecnico: this.form.nomeTecnico,
        nome_popular: this.form.nomePopular,
        descricao: this.form.descricao,
        contraindicacao: this.form.contraindicacao,
        efeito_colateral: this.form.efeitoColateral,
        cuidados: this.form.cuidados,
        codigo_smd:this.form.codeSmdVaccine,
        codigo_smd_integracao: this.form.codeExternalIntegration,
        id_tipo_aplicacao:
          this.form.idTipoAplicacaoSelecionado.id_tipo_aplicacao,
        ids_doencas: this.$helpers.retornaAtributoArray(
          this.doencasSelecionadas,
          "id_doenca"
        ),
      };
      this.$http
        .post(this.$api.saveAndGetVacina(), parameters)
        .then(() => {
          if (redirectToList) {
            this.openModalSucesso();
          } else {
            this.savedAlert = !this.savedAlert;
          }

          this.saving = false;
          this.savingAndNew = false;
          this.cleanForm();
        })
        .catch(({ response: { data } }) => {
          handleErrorsService.handleErrosVacina(data, this.form.errorApi);
          this.saving = false;
          this.savingAndNew = false;
        });
    },

    cleanForm() {
      this.form.nomeTecnico = "";
      this.form.nomePopular = "";
      this.form.descricao = "";
      this.form.contraindicacao = "";
      this.form.efeitoColateral = "";
      this.form.cuidados = "";
      this.form.idTipoAplicacaoSelecionado = "";
      this.doencasSelecionadas = [];

      this.form.errorApi.nomeTecnico = "";
      this.form.errorApi.nomePopular = "";
      this.form.errorApi.descricao = "";
      this.form.errorApi.contraindicacao = "";
      this.form.errorApi.efeitoColateral = "";
      this.form.errorApi.cuidados = "";
      this.form.errorApi.idTipoAplicacaoSelecionado = "";
      this.form.errorApi.doencasSelecionadas = "";
      this.form.errorApi.error = "";

      this.$refs.vacinaRules.reset();
    },

    redirecionarLista() {
      this.$router.push({ name: "vacina-list" });
    },

    openModalConfirm() {
      this.modal.showModal = true;
    },

    closeModalConfirm() {
      this.modal.showModal = false;
    },

    openModalSucesso() {
      this.modalSucesso.showModal = true;
    },

    setDoenca(doencaIds) {
      this.doencasSelecionadas = doencaIds;
    },
  },
};
</script>
