var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('PageHeader',{attrs:{"screenName":"Cadastrar nova","linkItems":_vm.linkItems}}),_c('ConfirmModal',{attrs:{"itemIdentifier":"usuário","modalShow":_vm.modal.showModal,"typeModal":_vm.modal.typeModal},on:{"confirmed":_vm.redirecionarLista,"canceled":_vm.closeModalConfirm}}),_c('SucessoModal',{attrs:{"modalShow":_vm.modalSucesso.showModal,"typeModal":_vm.modalSucesso.typeModal,"caseModal":_vm.modalSucesso.caseModal},on:{"confirmed":_vm.redirecionarLista}}),_c('TimedAlert',{attrs:{"variant":"success","message":"Vacina salva com sucesso!","show":_vm.savedAlert}}),_c('main',{staticClass:"card p-2"},[_c('section',{staticClass:"form"},[_c('validation-observer',{ref:"vacinaRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome técnico da vacina*","label-for":"nome-tecnico-vacina-input","label-class":"label_size_vacina"}},[_c('validation-provider',{attrs:{"name":"NomeTecnicoVacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('X'.repeat(250)),expression:"'X'.repeat(250)"}],attrs:{"state":errors.length > 0 ||
                      _vm.form.errorApi.nomeTecnico ||
                      _vm.form.errorApi.error
                        ? false
                        : null,"id":"nome-tecnico-vacina-input","placeholder":"Ex: Varicela"},model:{value:(_vm.form.nomeTecnico),callback:function ($$v) {_vm.$set(_vm.form, "nomeTecnico", $$v)},expression:"form.nomeTecnico"}}),_c('small',{staticClass:"text-muted"},[_vm._v(" Nome conhecido no meio científico. ")]),_c('br'),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.nomeTecnico)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.nomeTecnico)+" ")]):_vm._e(),(_vm.form.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('SelectDoencas',{attrs:{"values":_vm.doencasSelecionadas,"errorApi":_vm.form.errorApi.doencasSelecionadas},on:{"GetDoenca":_vm.setDoenca}})],1),_c('b-col',{attrs:{"lg":"3","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome popular da vacina","label-for":"nome-popular-vacina-input","label-class":"label_size_vacina"}},[_c('validation-provider',{attrs:{"name":"NomePopularVacina"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('X'.repeat(250)),expression:"'X'.repeat(250)"}],attrs:{"state":errors.length > 0 || _vm.form.errorApi.nomePopular
                        ? false
                        : null,"id":"nome-popular-vacina-input","placeholder":"Ex: Catapora"},model:{value:(_vm.form.nomePopular),callback:function ($$v) {_vm.$set(_vm.form, "nomePopular", $$v)},expression:"form.nomePopular"}}),_c('small',{staticClass:"text-muted"},[_vm._v(" Nome conhecido popularmente. ")]),_c('br'),(_vm.form.errorApi.nomePopular)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.nomePopular)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"3","sm":"12"}},[_c('validation-provider',{attrs:{"name":"TipoAplicaçãoVacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de aplicação*","label-for":"id-tipo-aplicacao-selecionado-input","label-class":"label_size_vacina","state":errors.length > 0 ||
                    _vm.form.errorApi.idTipoAplicacaoSelecionado
                      ? false
                      : null}},[_c('v-select',{attrs:{"id":"id-tipo-aplicacao-selecionado-input","options":_vm.tiposAplicacaoSelect,"label":"descricao","placeholder":"Selecione a quantidade","clearable":false},model:{value:(_vm.form.idTipoAplicacaoSelecionado),callback:function ($$v) {_vm.$set(_vm.form, "idTipoAplicacaoSelecionado", $$v)},expression:"form.idTipoAplicacaoSelecionado"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.idTipoAplicacaoSelecionado)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.idTipoAplicacaoSelecionado)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"lg":"3","md":"3","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Código SMD da vacina","rules":"max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código SMD da Vacina","label-for":"codigo-smd-input","label-class":"label_size_vacina","state":errors.length > 0 ||
                    _vm.form.errorApi.idTipoAplicacaoSelecionado
                      ? false
                      : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.codeSmdVaccine
                        ? false
                        : null,"id":"codigo-smd-input"},model:{value:(_vm.form.codeSmdVaccine),callback:function ($$v) {_vm.$set(_vm.form, "codeSmdVaccine", $$v)},expression:"form.codeSmdVaccine"}}),_c('br'),(_vm.form.errorApi.codeSmdVaccine)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.codeSmdVaccine)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"lg":"3","md":"3","sm":"12"}},[_c('validation-provider',{attrs:{"name":"código para integração externa","rules":"max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Código para integração externa","label-for":"code-external-input","label-class":"label_size_vacina","state":errors.length > 0 ||
                    _vm.form.errorApi.idTipoAplicacaoSelecionado
                      ? false
                      : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.codeExternalIntegration
                        ? false
                        : null,"id":"code-external-input"},model:{value:(_vm.form.codeExternalIntegration),callback:function ($$v) {_vm.$set(_vm.form, "codeExternalIntegration", $$v)},expression:"form.codeExternalIntegration"}}),_c('br'),(_vm.form.errorApi.codeExternalIntegration)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.codeExternalIntegration)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição*","label-for":"descricao-vacina-input","label-class":"label_size_vacina"}},[_c('validation-provider',{attrs:{"name":"DescricaoVacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.descricao
                        ? false
                        : null,"id":"descricao-vacina-input","rows":"5","placeholder":"Adicione uma descrição para esta vacina","no-resize":""},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.descricao)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.descricao)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Contraindicações*","label-for":"contraindicacao-vacina-input","label-class":"label_size_vacina"}},[_c('validation-provider',{attrs:{"name":"ContraindicacaoVacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.contraindicacao
                        ? false
                        : null,"id":"contraindicacao-vacina-input","rows":"5","placeholder":"Adicione contraindicações para esta vacina","no-resize":""},model:{value:(_vm.form.contraindicacao),callback:function ($$v) {_vm.$set(_vm.form, "contraindicacao", $$v)},expression:"form.contraindicacao"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.contraindicacao)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.contraindicacao)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Possíveis eventos adversos*","label-for":"efeito-colateral-vacina-input","label-class":"label_size_vacina"}},[_c('validation-provider',{attrs:{"name":"EfeitoColateralVacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.efeitoColateral
                        ? false
                        : null,"id":"efeito-colateral-vacina-input","rows":"5","placeholder":"Adicione possíveis eventos adversos para esta vacina","no-resize":""},model:{value:(_vm.form.efeitoColateral),callback:function ($$v) {_vm.$set(_vm.form, "efeitoColateral", $$v)},expression:"form.efeitoColateral"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.efeitoColateral)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.efeitoColateral)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Cuidados antes, durante e após a vacinação*","label-for":"cuidados-vacina-input","label-class":"label_size_vacina"}},[_c('validation-provider',{attrs:{"name":"CuidadosVacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.cuidados
                        ? false
                        : null,"id":"cuidados-vacina-input","rows":"5","placeholder":"Adicione cuidados para esta vacina","no-resize":""},model:{value:(_vm.form.cuidados),callback:function ($$v) {_vm.$set(_vm.form, "cuidados", $$v)},expression:"form.cuidados"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.cuidados)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.cuidados)+" ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1),_c('section',{staticClass:"buttons mt-3"},[_c('b-button',{staticClass:"mr-2",attrs:{"id":"save-vacina","variant":"custom-blue","disabled":_vm.saving || _vm.savingAndNew},on:{"click":function($event){$event.preventDefault();return _vm.validationForm(true)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Salvar vacina")]),(_vm.saving)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{staticClass:"mr-2",attrs:{"id":"save-vacina","variant":"custom-blue","disabled":_vm.saving || _vm.savingAndNew},on:{"click":function($event){$event.preventDefault();return _vm.validationForm(false)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Salvar e cadastrar uma nova")]),(_vm.savingAndNew)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{attrs:{"id":"cancel-register-vacina","disabled":_vm.saving || _vm.savingAndNew,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.openModalConfirm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancelar")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }